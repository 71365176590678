import * as React from 'react';
import DownOutlined from '@ant-design/icons/DownOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
export default function getIcons({ suffixIcon, clearIcon, menuItemSelectedIcon, removeIcon, loading, multiple, hasFeedback, prefixCls, showArrow, feedbackIcon, }) {
    // Clear Icon
    let mergedClearIcon = clearIcon;
    if (!clearIcon) {
        mergedClearIcon = React.createElement(CloseCircleFilled, null);
    }
    // Validation Feedback Icon
    const getSuffixIconNode = (arrowIcon) => (React.createElement(React.Fragment, null,
        showArrow !== false && arrowIcon,
        hasFeedback && feedbackIcon));
    // Arrow item icon
    let mergedSuffixIcon = null;
    if (suffixIcon !== undefined) {
        mergedSuffixIcon = getSuffixIconNode(suffixIcon);
    }
    else if (loading) {
        mergedSuffixIcon = getSuffixIconNode(React.createElement(LoadingOutlined, { spin: true }));
    }
    else {
        const iconCls = `${prefixCls}-suffix`;
        mergedSuffixIcon = ({ open, showSearch }) => {
            if (open && showSearch) {
                return getSuffixIconNode(React.createElement(SearchOutlined, { className: iconCls }));
            }
            return getSuffixIconNode(React.createElement(DownOutlined, { className: iconCls }));
        };
    }
    // Checked item icon
    let mergedItemIcon = null;
    if (menuItemSelectedIcon !== undefined) {
        mergedItemIcon = menuItemSelectedIcon;
    }
    else if (multiple) {
        mergedItemIcon = React.createElement(CheckOutlined, null);
    }
    else {
        mergedItemIcon = null;
    }
    let mergedRemoveIcon = null;
    if (removeIcon !== undefined) {
        mergedRemoveIcon = removeIcon;
    }
    else {
        mergedRemoveIcon = React.createElement(CloseOutlined, null);
    }
    return {
        clearIcon: mergedClearIcon,
        suffixIcon: mergedSuffixIcon,
        itemIcon: mergedItemIcon,
        removeIcon: mergedRemoveIcon,
    };
}
