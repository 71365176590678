var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import CheckableTag from './CheckableTag';
import { ConfigContext } from '../config-provider';
import { PresetColorTypes, PresetStatusColorTypes } from '../_util/colors';
import Wave from '../_util/wave';
export { CheckableTagProps } from './CheckableTag';
const PresetColorRegex = new RegExp(`^(${PresetColorTypes.join('|')})(-inverse)?$`);
const PresetStatusColorRegex = new RegExp(`^(${PresetStatusColorTypes.join('|')})$`);
const InternalTag = (_a, ref) => {
    var { prefixCls: customizePrefixCls, className, style, children, icon, color, onClose, closeIcon, closable = false } = _a, props = __rest(_a, ["prefixCls", "className", "style", "children", "icon", "color", "onClose", "closeIcon", "closable"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const [visible, setVisible] = React.useState(true);
    React.useEffect(() => {
        if ('visible' in props) {
            setVisible(props.visible);
        }
    }, [props.visible]);
    const isPresetColor = () => {
        if (!color) {
            return false;
        }
        return PresetColorRegex.test(color) || PresetStatusColorRegex.test(color);
    };
    const tagStyle = Object.assign({ backgroundColor: color && !isPresetColor() ? color : undefined }, style);
    const presetColor = isPresetColor();
    const prefixCls = getPrefixCls('tag', customizePrefixCls);
    const tagClassName = classNames(prefixCls, {
        [`${prefixCls}-${color}`]: presetColor,
        [`${prefixCls}-has-color`]: color && !presetColor,
        [`${prefixCls}-hidden`]: !visible,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    const handleCloseClick = (e) => {
        e.stopPropagation();
        onClose === null || onClose === void 0 ? void 0 : onClose(e);
        if (e.defaultPrevented) {
            return;
        }
        if (!('visible' in props)) {
            setVisible(false);
        }
    };
    const renderCloseIcon = () => {
        if (closable) {
            return closeIcon ? (React.createElement("span", { className: `${prefixCls}-close-icon`, onClick: handleCloseClick }, closeIcon)) : (React.createElement(CloseOutlined, { className: `${prefixCls}-close-icon`, onClick: handleCloseClick }));
        }
        return null;
    };
    const isNeedWave = 'onClick' in props || (children && children.type === 'a');
    const tagProps = omit(props, ['visible']);
    const iconNode = icon || null;
    const kids = iconNode ? (React.createElement(React.Fragment, null,
        iconNode,
        React.createElement("span", null, children))) : (children);
    const tagNode = (React.createElement("span", Object.assign({}, tagProps, { ref: ref, className: tagClassName, style: tagStyle }),
        kids,
        renderCloseIcon()));
    return isNeedWave ? React.createElement(Wave, null, tagNode) : tagNode;
};
const Tag = React.forwardRef(InternalTag);
Tag.displayName = 'Tag';
Tag.CheckableTag = CheckableTag;
export default Tag;
