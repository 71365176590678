var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { ConfigContext } from '../config-provider';
import warning from '../_util/warning';
export const GroupSizeContext = React.createContext(undefined);
const ButtonGroup = props => {
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, size, className } = props, others = __rest(props, ["prefixCls", "size", "className"]);
    const prefixCls = getPrefixCls('btn-group', customizePrefixCls);
    // large => lg
    // small => sm
    let sizeCls = '';
    switch (size) {
        case 'large':
            sizeCls = 'lg';
            break;
        case 'small':
            sizeCls = 'sm';
            break;
        case 'middle':
        case undefined:
            break;
        default:
            warning(!size, 'Button.Group', 'Invalid prop `size`.');
    }
    const classes = classNames(prefixCls, {
        [`${prefixCls}-${sizeCls}`]: sizeCls,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    return (React.createElement(GroupSizeContext.Provider, { value: size },
        React.createElement("div", Object.assign({}, others, { className: classes }))));
};
export default ButtonGroup;
