import * as React from 'react';
const defaultGetPrefixCls = (suffixCls, customizePrefixCls) => {
    if (customizePrefixCls)
        return customizePrefixCls;
    return suffixCls ? `ant-${suffixCls}` : 'ant';
};
// zombieJ: 🚨 Do not pass `defaultRenderEmpty` here since it will case circular dependency.
export const ConfigContext = React.createContext({
    // We provide a default function for Context without provider
    getPrefixCls: defaultGetPrefixCls,
});
export const ConfigConsumer = ConfigContext.Consumer;
/** @deprecated Use hooks instead. This is a legacy function */
export function withConfigConsumer(config) {
    return function withConfigConsumerFunc(Component) {
        // Wrap with ConfigConsumer. Since we need compatible with react 15, be care when using ref methods
        const SFC = ((props) => (React.createElement(ConfigConsumer, null, (configProps) => {
            const { prefixCls: basicPrefixCls } = config;
            const { getPrefixCls } = configProps;
            const { prefixCls: customizePrefixCls } = props;
            const prefixCls = getPrefixCls(basicPrefixCls, customizePrefixCls);
            return React.createElement(Component, Object.assign({}, configProps, props, { prefixCls: prefixCls }));
        })));
        const cons = Component.constructor;
        const name = (cons && cons.displayName) || Component.name || 'Component';
        SFC.displayName = `withConfigConsumer(${name})`;
        return SFC;
    };
}
