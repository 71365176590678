// TODO: 4.0 - codemod should help to change `filterOption` to support node props.
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import RcSelect, { BaseSelectRef, OptGroup, Option } from 'rc-select';
import { OptionProps } from 'rc-select/lib/Option';
import omit from 'rc-util/lib/omit';
import * as React from 'react';
import { useContext } from 'react';
import { ConfigContext } from '../config-provider';
import SizeContext from '../config-provider/SizeContext';
import DisabledContext from '../config-provider/DisabledContext';
import { FormItemInputContext } from '../form/context';
import { getTransitionDirection, getTransitionName } from '../_util/motion';
import { getMergedStatus, getStatusClassNames } from '../_util/statusUtils';
import defaultRenderEmpty from '../config-provider/defaultRenderEmpty';
import getIcons from './utils/iconUtil';
export { OptionProps, BaseSelectRef as RefSelectProps };
const SECRET_COMBOBOX_MODE_DO_NOT_USE = 'SECRET_COMBOBOX_MODE_DO_NOT_USE';
const InternalSelect = (_a, ref) => {
    var { prefixCls: customizePrefixCls, bordered = true, className, getPopupContainer, dropdownClassName, listHeight = 256, placement, listItemHeight = 24, size: customizeSize, disabled: customDisabled, notFoundContent, status: customStatus, showArrow } = _a, props = __rest(_a, ["prefixCls", "bordered", "className", "getPopupContainer", "dropdownClassName", "listHeight", "placement", "listItemHeight", "size", "disabled", "notFoundContent", "status", "showArrow"]);
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, renderEmpty, direction, virtual, dropdownMatchSelectWidth, } = React.useContext(ConfigContext);
    const size = React.useContext(SizeContext);
    const prefixCls = getPrefixCls('select', customizePrefixCls);
    const rootPrefixCls = getPrefixCls();
    const mode = React.useMemo(() => {
        const { mode: m } = props;
        if (m === 'combobox') {
            return undefined;
        }
        if (m === SECRET_COMBOBOX_MODE_DO_NOT_USE) {
            return 'combobox';
        }
        return m;
    }, [props.mode]);
    const isMultiple = mode === 'multiple' || mode === 'tags';
    const mergedShowArrow = showArrow !== undefined ? showArrow : props.loading || !(isMultiple || mode === 'combobox');
    // ===================== Form Status =====================
    const { status: contextStatus, hasFeedback, isFormItemInput, feedbackIcon, } = useContext(FormItemInputContext);
    const mergedStatus = getMergedStatus(contextStatus, customStatus);
    // ===================== Empty =====================
    let mergedNotFound;
    if (notFoundContent !== undefined) {
        mergedNotFound = notFoundContent;
    }
    else if (mode === 'combobox') {
        mergedNotFound = null;
    }
    else {
        mergedNotFound = (renderEmpty || defaultRenderEmpty)('Select');
    }
    // ===================== Icons =====================
    const { suffixIcon, itemIcon, removeIcon, clearIcon } = getIcons(Object.assign(Object.assign({}, props), { multiple: isMultiple, hasFeedback,
        feedbackIcon, showArrow: mergedShowArrow, prefixCls }));
    const selectProps = omit(props, ['suffixIcon', 'itemIcon']);
    const rcSelectRtlDropdownClassName = classNames(dropdownClassName, {
        [`${prefixCls}-dropdown-${direction}`]: direction === 'rtl',
    });
    const mergedSize = customizeSize || size;
    // ===================== Disabled =====================
    const disabled = React.useContext(DisabledContext);
    const mergedDisabled = customDisabled || disabled;
    const mergedClassName = classNames({
        [`${prefixCls}-lg`]: mergedSize === 'large',
        [`${prefixCls}-sm`]: mergedSize === 'small',
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-borderless`]: !bordered,
        [`${prefixCls}-in-form-item`]: isFormItemInput,
    }, getStatusClassNames(prefixCls, mergedStatus, hasFeedback), className);
    // ===================== Placement =====================
    const getPlacement = () => {
        if (placement !== undefined) {
            return placement;
        }
        return direction === 'rtl'
            ? 'bottomRight'
            : 'bottomLeft';
    };
    return (React.createElement(RcSelect, Object.assign({ ref: ref, virtual: virtual, dropdownMatchSelectWidth: dropdownMatchSelectWidth }, selectProps, { transitionName: getTransitionName(rootPrefixCls, getTransitionDirection(placement), props.transitionName), listHeight: listHeight, listItemHeight: listItemHeight, mode: mode, prefixCls: prefixCls, placement: getPlacement(), direction: direction, inputIcon: suffixIcon, menuItemSelectedIcon: itemIcon, removeIcon: removeIcon, clearIcon: clearIcon, notFoundContent: mergedNotFound, className: mergedClassName, getPopupContainer: getPopupContainer || getContextPopupContainer, dropdownClassName: rcSelectRtlDropdownClassName, showArrow: hasFeedback || showArrow, disabled: mergedDisabled })));
};
const Select = React.forwardRef(InternalSelect);
Select.SECRET_COMBOBOX_MODE_DO_NOT_USE = SECRET_COMBOBOX_MODE_DO_NOT_USE;
Select.Option = Option;
Select.OptGroup = OptGroup;
export default Select;
