var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { ConfigContext } from '../config-provider';
const Comment = (_a) => {
    var { actions, author, avatar, children, className, content, prefixCls: customizePrefixCls, datetime } = _a, otherProps = __rest(_a, ["actions", "author", "avatar", "children", "className", "content", "prefixCls", "datetime"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const renderNested = (prefixCls, nestedChildren) => (React.createElement("div", { className: classNames(`${prefixCls}-nested`) }, nestedChildren));
    const prefixCls = getPrefixCls('comment', customizePrefixCls);
    const avatarDom = avatar ? (React.createElement("div", { className: `${prefixCls}-avatar` }, typeof avatar === 'string' ? React.createElement("img", { src: avatar, alt: "comment-avatar" }) : avatar)) : null;
    const actionDom = actions && actions.length ? (React.createElement("ul", { className: `${prefixCls}-actions` }, actions.map((action, index) => (React.createElement("li", { key: `action-${index}` }, action) // eslint-disable-line react/no-array-index-key
    )))) : null;
    const authorContent = (author || datetime) && (React.createElement("div", { className: `${prefixCls}-content-author` },
        author && React.createElement("span", { className: `${prefixCls}-content-author-name` }, author),
        datetime && React.createElement("span", { className: `${prefixCls}-content-author-time` }, datetime)));
    const contentDom = (React.createElement("div", { className: `${prefixCls}-content` },
        authorContent,
        React.createElement("div", { className: `${prefixCls}-content-detail` }, content),
        actionDom));
    const cls = classNames(prefixCls, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    return (React.createElement("div", Object.assign({}, otherProps, { className: cls }),
        React.createElement("div", { className: `${prefixCls}-inner` },
            avatarDom,
            contentDom),
        children ? renderNested(prefixCls, children) : null));
};
export default Comment;
