import * as React from 'react';
import Empty from '../empty';
import { ConfigConsumer } from '.';
const defaultRenderEmpty = (componentName) => (React.createElement(ConfigConsumer, null, ({ getPrefixCls }) => {
    const prefix = getPrefixCls('empty');
    switch (componentName) {
        case 'Table':
        case 'List':
            return React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE });
        case 'Select':
        case 'TreeSelect':
        case 'Cascader':
        case 'Transfer':
        case 'Mentions':
            return React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, className: `${prefix}-small` });
        /* istanbul ignore next */
        default:
            // Should never hit if we take all the component into consider.
            return React.createElement(Empty, null);
    }
}));
export default defaultRenderEmpty;
