import * as React from 'react';
import classNames from 'classnames';
import { withConfigConsumer } from '../config-provider/context';
import Skeleton from '../skeleton';
import StatisticNumber from './Number';
const Statistic = props => {
    const { prefixCls, className, style, valueStyle, value = 0, title, valueRender, prefix, suffix, loading, direction, onMouseEnter, onMouseLeave, } = props;
    const valueNode = React.createElement(StatisticNumber, Object.assign({}, props, { value: value }));
    const cls = classNames(prefixCls, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    return (React.createElement("div", { className: cls, style: style, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
        title && React.createElement("div", { className: `${prefixCls}-title` }, title),
        React.createElement(Skeleton, { paragraph: false, loading: loading, className: `${prefixCls}-skeleton` },
            React.createElement("div", { style: valueStyle, className: `${prefixCls}-content` },
                prefix && React.createElement("span", { className: `${prefixCls}-content-prefix` }, prefix),
                valueRender ? valueRender(valueNode) : valueNode,
                suffix && React.createElement("span", { className: `${prefixCls}-content-suffix` }, suffix)))));
};
Statistic.defaultProps = {
    decimalSeparator: '.',
    groupSeparator: ',',
    loading: false,
};
const WrapperStatistic = withConfigConsumer({
    prefixCls: 'statistic',
})(Statistic);
export default WrapperStatistic;
