var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcSlider from 'rc-slider';
import classNames from 'classnames';
import SliderTooltip from './SliderTooltip';
import { ConfigContext } from '../config-provider';
const Slider = React.forwardRef((props, ref) => {
    const { getPrefixCls, direction, getPopupContainer } = React.useContext(ConfigContext);
    const [visibles, setVisibles] = React.useState({});
    const toggleTooltipVisible = (index, visible) => {
        setVisibles((prev) => (Object.assign(Object.assign({}, prev), { [index]: visible })));
    };
    const getTooltipPlacement = (tooltipPlacement, vertical) => {
        if (tooltipPlacement) {
            return tooltipPlacement;
        }
        if (!vertical) {
            return 'top';
        }
        return direction === 'rtl' ? 'left' : 'right';
    };
    const { prefixCls: customizePrefixCls, tooltipPrefixCls: customizeTooltipPrefixCls, range, className } = props, restProps = __rest(props, ["prefixCls", "tooltipPrefixCls", "range", "className"]);
    const prefixCls = getPrefixCls('slider', customizePrefixCls);
    const tooltipPrefixCls = getPrefixCls('tooltip', customizeTooltipPrefixCls);
    const cls = classNames(className, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    });
    // make reverse default on rtl direction
    if (direction === 'rtl' && !restProps.vertical) {
        restProps.reverse = !restProps.reverse;
    }
    // Range config
    const [mergedRange, draggableTrack] = React.useMemo(() => {
        if (!range) {
            return [false];
        }
        return typeof range === 'object' ? [true, range.draggableTrack] : [true, false];
    }, [range]);
    const handleRender = (node, info) => {
        const { index, dragging } = info;
        const rootPrefixCls = getPrefixCls();
        const { tipFormatter, tooltipVisible, tooltipPlacement, getTooltipPopupContainer, vertical } = props;
        const isTipFormatter = tipFormatter ? visibles[index] || dragging : false;
        const visible = tooltipVisible || (tooltipVisible === undefined && isTipFormatter);
        const passedProps = Object.assign(Object.assign({}, node.props), { onMouseEnter: () => toggleTooltipVisible(index, true), onMouseLeave: () => toggleTooltipVisible(index, false) });
        return (React.createElement(SliderTooltip, { prefixCls: tooltipPrefixCls, title: tipFormatter ? tipFormatter(info.value) : '', visible: visible, placement: getTooltipPlacement(tooltipPlacement, vertical), transitionName: `${rootPrefixCls}-zoom-down`, key: index, overlayClassName: `${prefixCls}-tooltip`, getPopupContainer: getTooltipPopupContainer || getPopupContainer }, React.cloneElement(node, passedProps)));
    };
    return (React.createElement(RcSlider, Object.assign({}, restProps, { step: restProps.step, range: mergedRange, draggableTrack: draggableTrack, className: cls, ref: ref, prefixCls: prefixCls, handleRender: handleRender })));
});
Slider.displayName = 'Slider';
Slider.defaultProps = {
    tipFormatter(value) {
        return typeof value === 'number' ? value.toString() : '';
    },
};
export default Slider;
