import * as React from 'react';
import useRCNotification from 'rc-notification/lib/useNotification';
import { ConfigConsumer } from '../../config-provider';
import { attachTypeApi, getKeyThenIncreaseKey, typeList } from '..';
export default function createUseMessage(getRcNotificationInstance, getRCNoticeProps) {
    const useMessage = () => {
        // We can only get content by render
        let getPrefixCls;
        let getPopupContainer;
        // We create a proxy to handle delay created instance
        let innerInstance = null;
        const proxy = {
            add: (noticeProps, holderCallback) => {
                innerInstance === null || innerInstance === void 0 ? void 0 : innerInstance.component.add(noticeProps, holderCallback);
            },
        };
        const [hookNotify, holder] = useRCNotification(proxy);
        function notify(args) {
            const { prefixCls: customizePrefixCls } = args;
            const mergedPrefixCls = getPrefixCls('message', customizePrefixCls);
            const rootPrefixCls = getPrefixCls();
            const target = args.key || getKeyThenIncreaseKey();
            const closePromise = new Promise(resolve => {
                const callback = () => {
                    if (typeof args.onClose === 'function') {
                        args.onClose();
                    }
                    return resolve(true);
                };
                getRcNotificationInstance(Object.assign(Object.assign({}, args), { prefixCls: mergedPrefixCls, rootPrefixCls,
                    getPopupContainer }), ({ prefixCls, instance }) => {
                    innerInstance = instance;
                    hookNotify(getRCNoticeProps(Object.assign(Object.assign({}, args), { key: target, onClose: callback }), prefixCls));
                });
            });
            const result = () => {
                if (innerInstance) {
                    innerInstance.removeNotice(target);
                }
            };
            result.then = (filled, rejected) => closePromise.then(filled, rejected);
            result.promise = closePromise;
            return result;
        }
        // Fill functions
        const hookApiRef = React.useRef({});
        hookApiRef.current.open = notify;
        typeList.forEach(type => attachTypeApi(hookApiRef.current, type));
        return [
            hookApiRef.current,
            React.createElement(ConfigConsumer, { key: "holder" }, (context) => {
                ({ getPrefixCls, getPopupContainer } = context);
                return holder;
            }),
        ];
    };
    return useMessage;
}
